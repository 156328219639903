 

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
 


body {
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  /*  font-family: "Roboto","Helvetica","Arial",sans-serif;*/
    overflow-y: scroll;

}


.container {
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.pricing {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.top10Item:hover{
    color: #8b8b8b;
}

.bottomBtns {
    padding: 0;
    margin-top: 1.5rem;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 41px;
}

.displayDiv {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: self-end;
}

.test {
    cursor: pointer;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}


.mojaGlava{
   /*/ text-align: center;
    font-size: 0.8rem;
    color: #999;
    padding-bottom: 25px;*/

     
    font-size: 0.75rem;
    font-weight: 600;
    color: #969696;
    padding-bottom: 8px;
    margin-bottom: 20px;
    text-transform: uppercase;
   /* display: none;*/
   position: relative;
   letter-spacing: 0.7px;
   display: flex;
   justify-content: space-between;
    
}
.mojaGlava.nadlistom {
    margin: 12px;
    margin-bottom: 5px;
}



.dodajIzdelekStoritev{
    padding: 12px;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
   justify-content: center;
    align-items: center;
    text-transform: uppercase;
   color:#4b4b4b;
   border-radius: 3px;
}
.dodajIzdelekStoritev:hover {
    color: black;
    box-shadow: rgb(173 173 173 / 20%) 0px 0px 4px 0px;
}


::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #cecece;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }